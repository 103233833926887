$(document).ready(function () {
	// appendDotsToText();
	initTypingAnimations();
	customAnimateElementsInView();
	initNewsletter();
	initSelectric();
	initLanding();
	initDetailGallery();
	initActivityBooking();
	initFloorPlans();
	customInstantBook();
	initSmallMeetingsWidget();
	initFormDatepicker();
	initNoticeBanner();
	initNoSlider();

	// Needs to fire after Slick-related code
	initLazyLoading();
});

function initSelectric() {
	$('select').selectric();
}


// Add padding when no slider to prevent header overlap
function initNoSlider() {
	if($(".slider").length > 0)
		{ $('main').addClass('hasslider');}
		else
		{ $('main').addClass('noslider');}
}

function initLazyLoading() {
	var lazyImages = Array.from($("[data-style], .lazy > source, .lazy-img"));

	var options = {
		root: null,
		rootMargin: "150px 0px",
		threshold: 0,
	  };

	var lazyImageObserver = new IntersectionObserver(callbackObserver, options);


	function presetImgSizeStyles(img) {
		if (
			img.nodeName === "SOURCE" ||
			img.nodeName === "DIV" ||
			img.classList.contains("bg-img") ||
			typeof img.dataset.presets !== "undefined"
		  ) {
			return;
		  }

		  img.style.width = "100%";
		  img.style.height = "auto";
		  img.style.display = "block";
		  img.decoding = "async";
	  }

	function setImageViewportWidth(img) {
		var windowWidth = window.innerWidth;
		var imgWidth = img.clientWidth;
		var imgViewportWidth = Math.floor((imgWidth / windowWidth) * 100) + "vw";
		img.sizes = imgViewportWidth;
	  }

	function handleImgSrcset(img) {
		setImageViewportWidth(img);
		img.srcset = img.dataset.srcset;
		img.src = img.dataset.src;
		delete img.dataset.srcset;
		delete img.dataset.src;
		lazyImageObserver.unobserve(img);

	  }

	function handleImgTag(img) {
		img.src = img.dataset.src;
		delete img.dataset.src;
		lazyImageObserver.unobserve(img);
	}

	function handleBackgroundImg(img) {
		img.style = img.dataset.style;
		delete img.dataset;
		lazyImageObserver.unobserve(img);
	  }

	function handlePictureElement(img) {
		if (img.nextElementSibling.nodeName === "IMG") {
		  img.nextElementSibling.src = img.nextElementSibling.dataset.src;
		  delete img.nextElementSibling.dataset.src;
		}

		img.srcset = img.dataset.srcset;
		delete img.dataset.srcset;

		img.parentElement.classList.remove("lazy");
		lazyImageObserver.unobserve(img);
	}

	function callbackObserver(entries) {
		entries.forEach(function(entry) {
		  var img = entry.target;

		  if (!entry.isIntersecting) return;

		  if (img.parentElement.nodeName === "PICTURE") {
			handlePictureElement(img);
		  }

		  if (img.nodeName === "IMG" && typeof img.dataset.srcset !== 'undefined') {
			handleImgSrcset(img);
		  }

		  if (img.nodeName === "IMG" && typeof img.dataset.srcset === "undefined") {
			handleImgTag(img);
		  }

		  if (img.nodeName === "DIV") {
			handleBackgroundImg(img);
		  }

		  lazyImageObserver.unobserve(img);
		});
	  }

	  lazyImages.forEach(function (img) {
		presetImgSizeStyles(img)
	  })

	  lazyImages.forEach(function (img) {
		lazyImageObserver.observe(img);
	  });
}



// Override
initSlider = function() {
	$('.slider').each(function () {
		$slider = $(this).find('.slider__inner');
		$sliderNav = $(this).find('.carousel-nav');

		$slider.slick({
			fade: true,
			arrows: true,
			dots: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: $sliderNav,
			appendDots: $sliderNav
		});

		var numOfSlides = $slider.find('.slick-slide').length;

		console.log(numOfSlides);


		var sliderButtons = $sliderNav.find('.slick-dots button');
    buttonPercentage = ( 100 / numOfSlides );


    var roundDown = Math.floor(buttonPercentage);
    sliderButtons.css('width', 'calc(' + roundDown + '% - 6px)');
	});

	if ($('.slider__video').length > 0) {
		// Make sure the slider always fills the whole screen
		coverViewport($('.slider__video iframe'), true);
		$(window).on('resize', function () {
			coverViewport($('.slider__video iframe'), true);
		});
	}
}


initHeader = function () {

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
		  deferTimer;
	  return function () {
		var context = scope || this;

		var now = +new Date,
			args = arguments;
		if (last && now < last + threshhold) {
		  // hold on to it
		  clearTimeout(deferTimer);
		  deferTimer = setTimeout(function () {
			last = now;
			fn.apply(context, args);
		  }, threshhold);
		} else {
		  last = now;
		  fn.apply(context, args);
		}
	  };
	}

	// Set the grid style for the header nav to fit the number of items it has
	var itemCount = $('.header__nav-primary li').length;
	if (itemCount > 0) {
		var headerNavCSS = '';
		for (var i=0; i < itemCount; i++) {
			headerNavCSS += 'auto ';
		}
		$('.header__nav-primary').css('grid-template-columns', headerNavCSS);
	}


	// Detect if the header is over and image slider, if yes then give it a transparent background
	if ( $('.slider, .directions').length > 0 ) {
		setHeaderTransparency();
		$(window).on('scroll', setHeaderTransparency);
	}

	function setHeaderTransparency() {
		if (window.pageYOffset < 10) {
			if ( $('.slider').length > 0 ) {
				$('.header').addClass('header--transparent');
			}
		} else {
			if ( $('.slider').length > 0 ) {
				$('.header').removeClass('header--transparent');
			}
		}
	}

	setHeaderDepth();
	$(window).on('scroll', setHeaderDepth);
	$(window).on('resize', setHeaderDepth);

	function setHeaderDepth() {
		if (window.pageYOffset < 10) {
			$('.header').removeClass('header--compact');
		} else {
			$('.header').addClass('header--compact');
		}

		// align the text in a way that's impossible with CSS alone
		$('.header__nav-primary span').each(function () {
			if ( ($(this).outerHeight() > 30) && ($(this).outerHeight() < 70) ) {
				$(this).addClass('offset');
			} else {
				$(this).removeClass('offset');
			}
		})
	}

	// Only show the header once we've completed the JS re-styling
	$('.header').addClass('header--visible');


	$('.header__nav-more-toggle').on('click', function () {
		$(this).toggleClass('open');
		$('.header__more').toggleClass('open');

		if ( $(this).hasClass('open') ) {
			$(this).find('span').text( $(this).data('close-text') );
		} else {
			$(this).find('span').text( $(this).data('open-text') );
		}
	});
}





initOffCanvas = function () {
	var overviewText = 'Overview';
	if (window.location.href.indexOf('/no/') > -1) {
		overviewText = 'Oversikt';
	}

/*
	$('.off-canvas__nav-primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">' + overviewText + '</a></li>');
		}
	}); */


	$('.off-canvas__nav-primary > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item allows clicking directly through
				return true;
			} else {
				// close any other open items
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();

				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}





// Fixing the arrow problem
var initGallery = function () {
	$('.gallery__item a').on('click', function () {
		var $clickedThumbnail = $(this);

		var closeText = 'Close';
		if (window.location.href.indexOf('/no/') > -1) {
			closeText = 'Lukk';
		}


		// Dynamically load the gallery slider into a reveal popup
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-inner grid-x"><button class="gallery__modal-close" aria-label="Close modal" type="button" data-close><svg xmlns="http://www.w3.org/2000/svg" width="45" height="33" viewBox="0 0 45 33"><rect id="burger-line-top" width="45" height="1" x="0" y="8" /><rect id="burger-line-middle" width="45" height="1" x="0" y="19" /><rect id="burger-line-bottom" width="45" height="1" x="0" y="31" /></svg><span aria-hidden="true">' + closeText + '</span></button><div class="gallery__modal-image cell medium-10 medium-offset-1"><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content cell medium-10 medium-offset-1 grid-x grid-margin-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div></div>';

		modal = modal.replace('{{src}}', $(this).attr('href'));
		modal = modal.replace('{{caption}}', $(this).data('title') || '');

		var cta = $(this).data('description') || '';
		if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
			cta += '<a href="' + $(this).data('book-link') + '" class="button button--primary" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
		}
		modal = modal.replace('{{cta}}', cta);

		var $modal = modal;

		$('body').append($modal);

		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});

		$('#gallery__modal').foundation('open');

		$('.gallery__modal-next').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
				$clickedThumbnail.parent().next(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
				$clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});

		$('.gallery__modal-prev').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
				$clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
				$clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});

		return false;
	});

	$('.gallery__item').each(function() {
		if( $(this).find('img').attr('width') > 300) {
			$(this).addClass('double-width')
		}

		if( $(this).find('img').height > 300) {
			$(this).addClass('height-2')
		}
	})

	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
	});

	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);

	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true
		});
	};

	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);



	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}



initHome = function () {
	$('.home__large-carousel-inner').slick({
		fade: true,
		arrows: true,
		dots: true,
		prevArrow: '<span class="slick-prev"></span>',
		nextArrow: '<span class="slick-next"></span>',
		appendArrows: '.home__large-carousel .carousel-nav',
		appendDots: '.home__large-carousel .carousel-nav'
	});

	$('.home__small-carousel-inner').slick({
		fade: true,
		arrows: true,
		dots: true,
		prevArrow: '<span class="slick-prev"></span>',
		nextArrow: '<span class="slick-next"></span>',
		appendArrows: '.home__small-carousel .carousel-nav',
		appendDots: '.home__small-carousel .carousel-nav'
	});
}



function appendDotsToText() {
	var selectorString = '';
	if (typeof projectSettings.appendDotsToText === "object") {
		selectorString = projectSettings.appendDotsToText.join(',');
	} else {
		selectorString = projectSettings.appendDotsToText;
	}


	$(selectorString).each(function () {
		$(this).text( $(this).text() + '.' );
	});
}


initAnimateInView = function () {
	$(window).on('DOMContentLoaded load resize scroll', customAnimateElementsInView);
	customAnimateElementsInView();

	$('.animated-border').append('<div class="animated-border-top"></div><div class="animated-border-right"></div><div class="animated-border-bottom"></div><div class="animated-border-left"></div>');
}

isElementInViewport = function(el) {
	var rect = el[0].getBoundingClientRect();
	//var midPoint = rect.top + (rect.height / 2);
	var triggerPoint = rect.top + 100;


	if (
		( triggerPoint >= 0 ) &&
		( triggerPoint <= window.innerHeight )
	) {
		return true;
	} else {
		return false;
	}
}

animateElementsInView = function () {
	// Completely disable this Archi function.
	// I need to control when my own version (below) gets fired
}

function customAnimateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') ).addClass('in-view');
		}
	});



	var selectorString = '';
	if (typeof projectSettings.applyTypingAnimation === "object") {
		selectorString = projectSettings.applyTypingAnimation.join(',');
	} else {
		selectorString = projectSettings.applyTypingAnimation;
	}

	$(selectorString).each(function () {
		if ( isElementInViewport($(this)) ) {
			var delayAmount = 0;
			var delayStep = 1500 / $(this).find('span').length;

			if (delayStep == Infinity) {
				delayStep = 15;
			} else if ( delayStep > 50 ) {
				delayStep = 50;
			}

			$(this).find('span').each(function () {
				var $span = $(this);

				setTimeout(function () {
					$span.css('opacity', 1);
				}, delayAmount);

				delayAmount += delayStep;
			});

			$(this).addClass('in-view');
		}
	});
}


function initTypingAnimations() {
	var selectorString = '';
	if (typeof projectSettings.applyTypingAnimation === "object") {
		selectorString = projectSettings.applyTypingAnimation.join(',');
	} else {
		selectorString = projectSettings.applyTypingAnimation;
	}

	$(selectorString).each(function () {
		$(this).attr('data-type-in-view', '');

		if ( $(this).children().length ) return; // skip if there's child elements that will disappear if we try to animate the text

		var charsArray = $(this).text().split('');
		var charsSpanned = '';
		for (var i=0; i<charsArray.length; i++) {
			if (charsArray[i] == ' ') {
				charsSpanned += ' ';
			} else {
				charsSpanned += '<span>' + charsArray[i] + '</span>';
			}
		}
		$(this).html(charsSpanned);
	});
}




function initLanding() {
	$('.landing__item').each(function () {
		$landingSlider = $(this).find('.landing__item-slider-inner');
		$landingSliderNav = $(this).find('.carousel-nav');

		$landingSlider.slick({
			fade: true,
			arrows: true,
			dots: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: $landingSliderNav,
			appendDots: $landingSliderNav
		});
		var numOfSlides = $landingSlider.find('.slick-slide').length;
		if ( numOfSlides < 2 ) {
			$(this).addClass('no-slider');
		}

		if( $(this).find('.landing__item-background').length < 1 ) {
			$(this).addClass('no-bg');
		}
	});
}



initAccommodation = function () {
	$('.accommodation__item').each(function () {
		$accommodationSlider = $(this).find('.accommodation__slider-inner');
		$accommodationSliderNav = $(this).find('.carousel-nav');

		$accommodationSlider.slick({
			fade: false,
			arrows: true,
			dots: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: $accommodationSliderNav,
			appendDots: $accommodationSliderNav
		});
	});
}



function initDetailGallery() {
	$('.detail-gallery').each(function () {
		$detailSlider = $(this).find('.detail-gallery__inner');
		$detailSliderNav = $(this).find('.carousel-nav');

		$detailSlider.slick({
			fade: true,
			arrows: true,
			dots: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: $detailSliderNav,
			appendDots: $detailSliderNav
		});
	});
}



initOtherRooms = function () {
	var slidesToShow = 3;

	if ( $('.other-rooms__carousel').length > 0 ) {
		setOtherRoomsHeight();
		$(window).on('resize', setOtherRoomsHeight);
	}

	$('.other-rooms__carousel').each(function () {
		var numberOfRooms = $(this).find('.other-rooms__room').length;

		if ( numberOfRooms > 3 ) {
			$(this).addClass('center-mode');
		} else if (numberOfRooms == 1) {
			$(this).addClass('one-room');
			slidesToShow = 1;
		} else if (numberOfRooms == 2) {
			$(this).addClass('two-rooms');
			slidesToShow = 2;
		} else if (numberOfRooms == 3) {
			$(this).addClass('three-rooms');
			slidesToShow = 3;
		}
	});

	$('.other-rooms__carousel').slick({
		slidesToShow: slidesToShow,
		centerMode: true,
		centerPadding: 0,
		dots: true,
		prevArrow: '<span class="slick-prev"></span>',
		nextArrow: '<span class="slick-next"></span>',
		appendArrows: $('.other-rooms .carousel-nav'),
		appendDots: $('.other-rooms .carousel-nav'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}


function setOtherRoomsHeight() {
	// stops the carousel nav from being all jumpy jumpy
	$('.other-rooms__wrapper').css('min-height', $('.other-rooms__room').outerHeight() + 50);
}



function initActivityBooking() {
	// Load the 3rd party Citybreaks widget into a popup
	$('a[href*="#activity-"]').on('click', function () {
		var activityID =  $(this).attr('href').split('-')[1];

		var closeText = 'Close';
		if (window.location.href.indexOf('/no/') > -1) {
			closeText = 'Lukk';
		}

		var popupMarkup = '<div class="popup reveal full" id="activity-popup" data-reveal><button class="popup__close" aria-label="Close modal" type="button" data-close><svg xmlns="http://www.w3.org/2000/svg" width="45" height="33" viewBox="0 0 45 33"><rect id="burger-line-top" width="45" height="1" x="0" y="8" /><rect id="burger-line-middle" width="45" height="1" x="0" y="19" /><rect id="burger-line-bottom" width="45" height="1" x="0" y="31" /></svg><span aria-hidden="true">' + closeText + '</span></button><div class="popup__wrap grid-x"><div id="citybreak_activity_booking_widget"></div><div class="popup__text cell" id="activity_booking_widget-{ID}"></div></div></div>';

		popupMarkup = popupMarkup.replace('{ID}', activityID);

		if ( typeof activityID !== 'undefined' ) {
			$('#activity-popup').remove();

			$('body').append(popupMarkup);
			$('#activity-popup').foundation();
			$('#activity-popup').foundation('open');

			if ( !$('body').hasClass('cb-init') ) {
				var widget = document.createElement('script'); widget.type = 'text/javascript'; widget.async = true;
				widget.src = 'https://www.trolltunga-active.com/book/activitywidget/booking';
				var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(widget, s);

				document.addEventListener('cb-activity-widget-loaded', function() {
					window.citybreakActivityBooking.initContainer(document.getElementById('activity_booking_widget-' + activityID), {
						cbisProductId: activityID
					});
				}, false);

				$('body').addClass('cb-init');
			}
		}

		return false;
	});



	// Ticket sales come from Hoopla. There's only ever 1 link.
	$('a[href="#tickets"]').on('click', function () {
		if ( $('#ticket-popup').length === 0 ) {
			var closeText = 'Close';
			if (window.location.href.indexOf('/no/') > -1) {
				closeText = 'Lukk';
			}

			var popupMarkup = '<div class="popup reveal full" id="ticket-popup" data-reveal><button class="popup__close" aria-label="Close modal" type="button" data-close><svg xmlns="http://www.w3.org/2000/svg" width="45" height="33" viewBox="0 0 45 33"><rect id="burger-line-top" width="45" height="1" x="0" y="8" /><rect id="burger-line-middle" width="45" height="1" x="0" y="19" /><rect id="burger-line-bottom" width="45" height="1" x="0" y="31" /></svg><span aria-hidden="true">' + closeText + '</span></button><div class="popup__wrap grid-x"><div id="hoopla-sales-page-container"></div></div></div>';

			//$('#ticket-popup').remove();

			$('body').append(popupMarkup);
			$('#ticket-popup').foundation();
			$('#ticket-popup').foundation('open');

			var script = document.createElement('script');
			script.onload = function () {
				var renderReady = function(cb) { if (window.HooplaSalesPage) { cb(); } else { setTimeout(function() { renderReady(cb) }, 10); }}

				renderReady((function() {
					window.HooplaSalesPage.render({ organizationId: "502016017" }, '#hoopla-sales-page-container');
				}));
			};
			script.src = '//d16s6o6uu491xt.cloudfront.net/sales-3/loader.embed.production.js';

			document.head.appendChild(script);
		} else {
			$('#ticket-popup').foundation('open');
		}



		return false;
	});
}



function initFloorPlans() {
	$('a[href*="my.matterport.com"]').on('click', function () {
		var closeText = 'Close';
		if (window.location.href.indexOf('/no/') > -1) {
			closeText = 'Lukk';
		}

		var popupMarkup = '<div class="popup popup--floorplan reveal full" id="floorplan-popup" data-reveal><button class="popup__close" aria-label="Close modal" type="button" data-close><svg xmlns="http://www.w3.org/2000/svg" width="45" height="33" viewBox="0 0 45 33"><rect id="burger-line-top" width="45" height="1" x="0" y="8" /><rect id="burger-line-middle" width="45" height="1" x="0" y="19" /><rect id="burger-line-bottom" width="45" height="1" x="0" y="31" /></svg><span aria-hidden="true">' + closeText + '</span></button><div class="popup__wrap grid-x"><iframe src="{SRC}" frameborder="0" width="100%" height="680"></iframe></div></div></div>';

		popupMarkup = popupMarkup.replace('{SRC}', $(this).attr('href'));

		$('#floorplan-popup').remove();

		$('body').append(popupMarkup);
		$('#floorplan-popup').foundation();
		$('#floorplan-popup').foundation('open');

		return false;
	});


	//<iframe class="3d-link-iframe" src="https://my.matterport.com/show/?m=mExPW7GnXiN" allowfullscreen="" allow="vr" style="border: none; width: 100%; height: 480px; z-index: 1;"></iframe><div class="backlink-badge-container" style="position: absolute; top: 8px; right: 8px; z-index: 9;"><a href="https://www.headbox.com/" target="_blank" class="backlink"><img src="https://blog.headbox.com/wp-content/uploads/2021/10/3D-badge-white-brick.png" class="badge" alt="3D tour presented by HeadBox" style="width: 96px; height: 32px;"></a>
}


initForms = function () {
	// Change Archi core to do nothing
}


function customInstantBook() {
	if ( $('.instant-book__widget').length > 0 ) {
		var script = document.createElement('script');
		script.onload = function () {
			renderInstandBook()
		};
		script.src = 'https://www.smallmeetings.co.uk/widget/scripts/availability.min.js';

		document.head.appendChild(script);
	}
}

function renderInstandBook() {
	var count = 0;

	// Assign unique IDs incase there are multiple widgets on the page.
	$('.instant-book__widget').each(function () {
		$(this).attr('id', 'instant-book-' + count);
		availabilityWidget.init('#instant-book-' + count , $(this).data('key'));
		count++;
	});
}

function initSmallMeetingsWidget() {
	if ( $('.small-meetings-widget').length > 0 ) {
		var script = document.createElement('script');
		script.onload = function () {
			$('.small-meetings-widget').each(function () {
				availabilityWidget.init(".small-meetings-widget__holder", $(this).data('widget-key'), {columns: 2, buttonText:'check availability'});
			});

		};
		script.src = 'https://www.smallmeetings.co.uk/widget/scripts/availability.min.js';

		document.head.appendChild(script);
	}
}

function initFormDatepicker() {
	var today = new Date();

	$('.form__datepicker').datepicker({
		format: 'dd/m/yyyy',
		autoPick: false,
		autoHide: true,
		date: null,
		startDate: today
	});

}

function initNewsletter () {
	// function setDobOptions() {
	// 	var day = $(".day-dob");
	// 	for (i = 1; i < 32; i++) {
	// 	  day.append("<option value = " + i + ">" + i + "</option>");
	// 	}
	// 	var month = $(".month-dob");
	// 	for (j = 1; j < 13; j++) {
	// 	  month.append("<option value = " + j + ">" + j + "</option>");
	// 	}
	// 	var date = new Date();
	// 	var cYear = date.getFullYear();

	// 	var year = $(".year-dob");
	// 	var currentYear = new Date().getFullYear()
	// 	for (j = currentYear; j >= 1930; j--) {
	// 	  year.append("<option value = " + j + ">" + j + "</option>");
	// 	}
	//   }
	// setDobOptions()

	function toggleOffCanvas() {
		var toggleBtn = $('[data-toggle-newsletter]')
		var closeBtn = $('.offCanvas-newsletter__close')
		var offCanvas = $('.offCanvas-newsletter')

		toggleBtn.on('click', function () {
			offCanvas.toggleClass('on')
		})
		closeBtn.on('click', function () {
			offCanvas.removeClass('on')
		})

	}
	toggleOffCanvas()
}


function initNoticeBanner() {
	var notice = $('.notice-banner')
	var closeBtn = $('.notice-banner__close')
	var noticeDelay = notice.data('delay') || 0;
	var noticeKeepHiddenFor = notice.data('notice-keep-hidden-for-n-days') || 0;

	if ( notice.length === 0 ) return

	if (!Cookies.get('hasSeenNotice')) {
		setTimeout(function () {

			notice.removeClass('hide')

			if (noticeKeepHiddenFor > 0) {
				Cookies.set('hasSeenNotice', 'true', {expires: noticeKeepHiddenFor});
			}
		}, noticeDelay)
	}

	closeBtn.on('click', function () {
		notice.addClass('hide')
		notice.remove()
	})
}

// for some reason, foundation isn't picking this up when it has multiple popups and banners so here's a quick fix
$('.popup__close').click(function() {
	$(this).closest('.popup.reveal').css('display', 'none');

	// Removes hidden from body on popup close
	$(document.body).removeClass("is-reveal-open");
})
